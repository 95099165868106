export function getServicePoints(params: ServicePointsParams) {
  const { $api } = useNuxtApp()

  return $api<PaginatedResult<ServicePoint>>('/service-points/', {
    method: 'GET',
    params,
  })
}

export function getServicesDevices(uriId: string) {
  const { $api } = useNuxtApp()

  return $api<GroupedDevices>(`/service-points/${uriId}/grouped-devices/`, {
    method: 'GET',
  })
}

export function getServicesRepairServices(uriId: string) {
  const { $api } = useNuxtApp()

  return $api<GroupedRepairServices>(`/service-points/${uriId}/grouped-repair-services/`, {
    method: 'GET',
  })
}

export function getServicePointByUriId(uriId: string) {
  const { $api } = useNuxtApp()

  return $api<ServicePoint>(`/service-points/${uriId}/`, {
    method: 'GET',
  })
}

export function getProblemRepairServicesByRepairServiceUriId(uriId: string, category: CategoryType, problemUriId: string) {
  const { $api } = useNuxtApp()

  return $api<Array<GroupedDevicesWithRepairServices>>(`/service-points/${uriId}/problem-repair-services/${problemUriId}/`, {
    method: 'GET',
    params: {
      device_type: categoriesMap[category],
    }
  })
}

export function getDeviceRepairServices(uriId: string, deviceUriId: string) {
  const { $api } = useNuxtApp()

  return $api<Array<RepairServiceWithTitle>>(`/service-points/${uriId}/device-repair-services/${deviceUriId}/`, {
    method: 'GET',
  })
}
